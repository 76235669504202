const debug = require('debug')('mtk:react:client');

import React, { Fragment } from 'react';
import { FiArrowUpRight, FiTrash2 } from 'react-icons/fi';

import api from '../utils/api';
import { useForm } from '../utils/form';
import { roles, roleLabels, modeLabels } from '../constants';

import { routes } from '../routes';
import { can } from '../can';

import { Loading, NotFound } from './PageStatus';

import PageHeader from '../components/common/PageHeader';
import Input, { InputRow } from '../components/common/Input';
import Toggle from '../components/common/Toggle';
import Select, { indexToOptions, objectToOptions } from '../components/common/Select';
import TextArea from '../components/common/TextArea';
import Button from '../components/common/Button';
import Link from '../components/common/Link';
import { List, Body, Row, Cell } from '../components/common/List';

import grid from '../styles/grid.css';

const clientFields = {
  name: ['text', true],
  segments: ['array'],
  preferences: {
    whatsappFromNumber: ['text'],
    displayPickupCode: ['toggle'],
    printOrderParams: ['text'],
    twoFactorAuth: ['toggle'],
    loadPickupUsers: ['toggle'],
    loadPickupUsersRole: ['text'],
    enableCreateUserFromPickupInfo: ['toggle'],
    mode: ['text'],
    extras: ['json'],
    supportEmail: ['email'],
    supportPhone: ['text'],
    fromName: ['text'],
    fromEmail: ['email'],
    sendCourierAssignedEmail: ['toggle'],
    sendCourierAssignedSms: ['toggle'],
    sendCourierAssignedWhatsApp: ['toggle'],
    sendCourierCancelledEmail: ['toggle'],
    sendCourierCancelledSms: ['toggle'],
    sendCourierCancelledWhatsApp: ['toggle'],
    sendCourierReadyEmail: ['toggle'],
    sendCourierReadySms: ['toggle'],
    sendCourierReadyWhatsApp: ['toggle'],
    sendPickupReadyEmail: ['toggle'],
    sendPickupReadySms: ['toggle'],
    sendPickupReadyWhatsApp: ['toggle'],
    sendPickupReminderEmail: ['toggle'],
    sendPickupReminderSms: ['toggle'],
    sendPickupReminderWhatsApp: ['toggle'],
    pickupReminderHours: [['number']],
    sendPickupReversalEmail: ['toggle'],
    sendPickupReversalSms: ['toggle'],
    sendPickupReversalWhatsApp: ['toggle'],
    sendCourierReversalEmail: ['toggle'],
    sendCourierReversalSms: ['toggle'],
    sendCourierReversalWhatsApp: ['toggle'],
    sendPickupCompletedEmail: ['toggle'],
    sendPickupCompletedSms: ['toggle'],
    sendPickupCompletedWhatsApp: ['toggle'],
    sendCourierCompletedEmail: ['toggle'],
    sendCourierCompletedSms: ['toggle'],
    sendCourierCompletedWhatsApp: ['toggle'],
    webhookUrl: ['url'],
    webhookSecret: ['text'],
    autoReversalEnabled: ['toggle'],
    autoReversalHours: ['number'],
    autoCancelReturnEnabled: ['toggle'],
    autoCancelReturnHours: ['number'],
    autoCancelReservedEnabled: ['toggle'],
    autoCancelReservedHours: ['number'],
    customDropCodeEnabled: ['toggle'],
    customDropCodeRequired: ['toggle'],
    customPickupCodeEnabled: ['toggle'],
    courierPickupCodeEnabled: ['toggle'],
    courierPickupCodeRequired: ['toggle'],
    returnsEnabled: ['toggle'],
    sendReturnReservedEmail: ['toggle'],
    sendReturnReservedSms: ['toggle'],
    sendReturnReservedWhatsApp: ['toggle'],
    sendReturnReadyEmail: ['toggle'],
    sendReturnReadySms: ['toggle'],
    sendReturnReadyWhatsApp: ['toggle'],
    sendReturnCancelledEmail: ['toggle'],
    sendReturnCancelledSms: ['toggle'],
    sendReturnCancelledWhatsApp: ['toggle'],
    sendReturnReversalEmail: ['toggle'],
    sendReturnReversalSms: ['toggle'],
    sendReturnReversalWhatsApp: ['toggle'],
  },
};

const Client = ({ match, history }) => {
  const { id } = match.params;
  const { user } = api.auth();

  const { item, actions } = api.clients.item(id, {}, { history });
  const { index: segmentsIndex, actions: segmentsActions } = api.segments.index(null, { lazy: true });
  const readOnly = !can(user, 'update', 'clients');
  const form = useForm(clientFields, item.data, {
    submit: (data) => {
      data = data.updateIn(['preferences'], (prefs) => item.data.get('preferences').merge(prefs));
      actions.update(data);
    },
  });

  const modeOptions = objectToOptions(modeLabels.obj);
  const roleOptions = objectToOptions(roleLabels.obj);

  const isAdmin = user.role == roles('admin');
  // ACTIONS
  const getSegments = async (search) => {
    const index = await segmentsActions.get({ search, scopeToClientId: 'false', perPage: 50 });
    return indexToOptions(index);
  };
  const onSegmentAdd = (name, value, option) => {
    actions.segmentAssign(value);
  };

  const onSegmentRemove = (name, value, option) => {
    actions.segmentUnassign(value);
  };

  const onDelete = async () => {
    await actions.delete();
  };

  if (item.error && item.status == 404) return <NotFound />;
  if (!item.loaded) return <Loading />;

  return (
    <main>
      <PageHeader
        readOnly={readOnly}
        section="clients"
        hideBreadcrumbs={!isAdmin}
        title={form.getValue('name')}
        dirty={form.dirty}
        autoSave
        {...item}>
        {can(user, 'delete', 'clients') && (
          <Button noMargin confirm="¿Eliminar este Cliente?" destructive onClick={onDelete}>
            Eliminar
          </Button>
        )}
      </PageHeader>

      <div className={grid.grid}>
        <section className={grid.half}>
          <h2>Configuración</h2>
          <Input readOnly={readOnly} name="name" form={form} label="Nombre" placeholder="Maletek" />
          <Select
            readOnly={readOnly}
            name={['preferences', 'mode']}
            isClearable={false}
            options={modeOptions}
            label="Modo"
            form={form}
            note={'Define el modo en el que opera el cliente.'}
            placeholder="..."
          />

          <br />
          <hr />
          {isAdmin && (
            <Fragment>
              <h3>Grupos</h3>
              <Select
                isMulti
                readOnly={readOnly}
                isClearable={false}
                hideValues
                name={'segments'}
                value={indexToOptions(form.getValue('segments'))}
                onChange={() => {}}
                onSelectOption={onSegmentAdd}
                onDeselectOption={onSegmentRemove}
                loadOptions={getSegments}
              />
              <List>
                <Body>
                  {item.data &&
                    item.data.has('segments') &&
                    item.data
                      .get('segments')
                      .toJS()
                      .map((s, i) => (
                        <Row key={`segment-${i}`}>
                          <Cell>{s.name}</Cell>

                          {readOnly ? null : (
                            <Cell right inline>
                              <a
                                href="#"
                                onClick={(e) => {
                                  e.preventDefault();
                                  actions.segmentUnassign(s.id);
                                }}>
                                <FiTrash2 />
                              </a>

                              <Link inline to={routes('segment', { id: s.id })}>
                                <FiArrowUpRight />
                              </Link>
                            </Cell>
                          )}
                        </Row>
                      ))}
                </Body>
              </List>
            </Fragment>
          )}
          <h2> Opciones de Orden </h2>
          <h3>Creación de orden</h3>
          <Toggle
            readOnly={readOnly}
            label="Habilitar carga de destinatario de orden desde lista de usuarios"
            name={['preferences', 'loadPickupUsers']}
            form={form}
          />
          {form.getValue(['preferences', 'loadPickupUsers']) && (
            <Select
              name={['preferences', 'loadPickupUsersRole']}
              isClearable={false}
              options={roleOptions}
              label="Rol de destinatario de orden"
              placeholder="..."
              form={form}
              readOnly={readOnly}
            />
          )}
          <Toggle
            readOnly={readOnly}
            label="Habilitar guardado de destinatarios como usuarios sin acceso"
            name={['preferences', 'enableCreateUserFromPickupInfo']}
            form={form}
          />
          <hr />
          <h3>Código de entrega </h3>
          <small style={{ marginBottom: '1rem' }}>El código es autogenerado si no es entregado al crearlo</small>
          <Toggle
            readOnly={readOnly}
            label="Habilitar personalización de código de entrega"
            name={['preferences', 'customDropCodeEnabled']}
            form={form}
          />
          {form.getValue(['preferences', 'customDropCodeEnabled']) && (
            <Toggle
              readOnly={readOnly}
              label="Exigir código de entrega personalizado"
              name={['preferences', 'customDropCodeRequired']}
              form={form}
            />
          )}
          <br />
          <hr />
          <h3>Código de retiro </h3>
          <small style={{ marginBottom: '1rem' }}>El código es autogenerado si no es entregado al crearlo</small>
          <Toggle
            readOnly={readOnly}
            label="Habilitar personalización de código de retiro"
            name={['preferences', 'customPickupCodeEnabled']}
            form={form}
          />
          <small style={{ marginBottom: '1rem' }}>Permite que los usuarios del cliente vean el códgo de retiro</small>
          <Toggle
            readOnly={readOnly}
            label="Mostrar código de retiro"
            name={['preferences', 'displayPickupCode']}
            form={form}
          />

          <br />
          <br />
          <hr />
          <h3> Código de retiro (revocación) </h3>
          <Toggle
            readOnly={readOnly}
            label="Habilitar código de retiro para revocación"
            name={['preferences', 'courierPickupCodeEnabled']}
            form={form}
          />
          {form.getValue(['preferences', 'courierPickupCodeEnabled']) && (
            <Toggle
              readOnly={readOnly}
              label="Exigir código de retiro para revocación"
              name={['preferences', 'courierPickupCodeRequired']}
              form={form}
            />
          )}
          <br />
          <hr />

          <h3>Devoluciones</h3>
          <Toggle
            readOnly={readOnly}
            label="Habilitar devoluciones"
            name={['preferences', 'returnsEnabled']}
            form={form}
          />

          <br />
          <hr />
          <h3>Autorevocación</h3>

          <Toggle
            readOnly={readOnly}
            label="Activar autorevocación de envíos"
            name={['preferences', 'autoReversalEnabled']}
            form={form}
          />
          {form.getValue(['preferences', 'autoReversalEnabled']) && (
            <Input
              readOnly={readOnly}
              label="Horas hasta autorevocación de un envío"
              placeholder="72"
              step={1}
              min={1}
              max={168}
              name={['preferences', 'autoReversalHours']}
              form={form}
              note="Cantidad de horas desde que el paquete es entregado en puerta"
            />
          )}

          {form.getValue(['preferences', 'returnsEnabled']) && (
            <>
              <Toggle
                readOnly={readOnly}
                label="Activar autorevocación de devoluciones"
                name={['preferences', 'autoCancelReturnEnabled']}
                form={form}
              />

              {form.getValue(['preferences', 'autoCancelReturnEnabled']) && (
                <Input
                  readOnly={readOnly}
                  label="Horas hasta autorevocación de una devolución"
                  placeholder="72"
                  step={1}
                  min={1}
                  max={168}
                  name={['preferences', 'autoCancelReturnHours']}
                  form={form}
                  note="Cantidad de horas desde que la puerta es reservada"
                />
              )}
            </>
          )}
          <br />
          <hr />
          <h3>Auto cancelación de reserva</h3>

          <Toggle
            readOnly={readOnly}
            label="Activar auto cancelar reserva"
            name={['preferences', 'autoCancelReservedEnabled']}
            form={form}
          />
          {form.getValue(['preferences', 'autoCancelReservedEnabled']) && (
            <Input
              readOnly={readOnly}
              label="Horas hasta auto cancelar reserva"
              placeholder="72"
              step={1}
              min={1}
              max={168}
              name={['preferences', 'autoCancelReservedHours']}
              form={form}
              note="Cantidad de horas desde que el paquete es reservado en el locker"
            />
          )}

          <br />
          <hr />
          <h3>Opciones de etiquetas</h3>
          <Input
            readOnly={readOnly}
            label="Parámetros para imprimir etiquetas"
            name={['preferences', 'printOrderParams']}
            placeholder="pickupName, pickupPhone, extras.item"
            form={form}
            note={
              'Parámetros de la orden a imprimr bajo el codigo de retiro en la etiqueta, separados por coma. Ej: order.pickupName, order.pickupPhone, order.extras.item'
            }
          />
          <br />
          <hr />
          <h3>Campos Extras</h3>
          <TextArea readOnly={readOnly} type="json" name={['preferences', 'extras']} form={form} />
        </section>
        <section className={grid.half}>
          <h2>Preferencias </h2>

          <Input label="Email de Soporte" name={['preferences', 'supportEmail']} form={form} readOnly={readOnly} />
          <Input label="Teléfono de Soporte" name={['preferences', 'supportPhone']} form={form} readOnly={readOnly} />

          <Toggle
            readOnly={readOnly}
            label="Requerir autorización de doble factor"
            name={['preferences', 'twoFactorAuth']}
            form={form}
          />
          <small>Requerir 2FA para todos los usuarios del cliente.</small>
          <br />
          <br />
          <hr />

          <h2>Notificaciones</h2>
          <br />
          <Input
            label="Número de teléfono para WhatsApp"
            name={['preferences', 'whatsappFromNumber']}
            placeholder="+13257700024"
            form={form}
            readOnly={readOnly}
            note="El teléfono debe estar registrado y validado en Meta for whatsApp Bussines. Se usará el número de cliente eLocker por defecto."
          />

          <h3>Remitente</h3>

          <Input
            label="Nombre remitente"
            name={['preferences', 'fromName']}
            placeholder="eLocker"
            form={form}
            readOnly={readOnly}
          />
          <Input
            label="Email remitente"
            name={['preferences', 'fromEmail']}
            placeholder="no-responder@email.elocker.cl"
            form={form}
            readOnly={readOnly}
            note="Email debe terminar en @elocker.cl o @elocker.com.pe, o haber sido validado con Maletek. Si no es válido los emails no se enviarán."
          />
          <hr />
          <h3>Notificaciones destinatario </h3>
          <Toggle
            readOnly={readOnly}
            label="Envío de SMS de orden lista para retiro"
            name={['preferences', 'sendPickupReadySms']}
            form={form}
          />
          <Toggle
            readOnly={readOnly}
            label="Envío de email de orden lista para retiro"
            name={['preferences', 'sendPickupReadyEmail']}
            form={form}
          />
          <Toggle
            readOnly={readOnly}
            label="Envío de whatsApp de orden lista para retiro"
            name={['preferences', 'sendPickupReadyWhatsApp']}
            form={form}
          />

          <hr />
          <Toggle
            readOnly={readOnly}
            label="Envío de SMS de recordatorio orden lista para retiro"
            name={['preferences', 'sendPickupReminderSms']}
            form={form}
          />
          <Toggle
            readOnly={readOnly}
            label="Envío de email de recordatorio orden lista para retiro"
            name={['preferences', 'sendPickupReminderEmail']}
            form={form}
          />
          <Toggle
            readOnly={readOnly}
            label="Envío de whatsApp de recordatorio orden lista para retiro"
            name={['preferences', 'sendPickupReminderWhatsApp']}
            form={form}
          />

          {(form.getValue(['preferences', 'sendPickupReminderSms']) ||
            form.getValue(['preferences', 'sendPickupReminderEmail']) ||
            form.getValue(['preferences', 'sendPickupReminderWhatsApp'])) && (
            <>
              <InputRow>
                {form.getValue(['preferences', 'pickupReminderHours']) &&
                  form.getValue(['preferences', 'pickupReminderHours']).map((item, index) => {
                    return (
                      <Input
                        key={`pickupReminderHours-${index}`}
                        label={`Recordatorio #${index + 1}`}
                        name={['preferences', 'pickupReminderHours', index]}
                        sufix="horas"
                        form={form}
                        readOnly={readOnly}
                      />
                    );
                  })}
              </InputRow>
              <Button onClick={() => form.appendValue(['preferences', 'pickupReminderHours'], null)}>+</Button>
              {form.getValue(['preferences', 'pickupReminderHours']).size > 1 && (
                <Button
                  onClick={() =>
                    form.deleteValue([
                      'preferences',
                      'pickupReminderHours',
                      form.getValue(['preferences', 'pickupReminderHours']).size - 1,
                    ])
                  }>
                  -
                </Button>
              )}
            </>
          )}
          <hr />
          <Toggle
            readOnly={readOnly}
            label="Envío de SMS envío completado"
            name={['preferences', 'sendPickupCompletedSms']}
            form={form}
          />
          <Toggle
            readOnly={readOnly}
            label="Envío de email envío completado"
            name={['preferences', 'sendPickupCompletedEmail']}
            form={form}
          />
          <Toggle
            readOnly={readOnly}
            label="Envío de whatsApp envío completado"
            name={['preferences', 'sendPickupCompletedWhatsApp']}
            form={form}
          />
          <hr />
          <Toggle
            readOnly={readOnly}
            label="Envío de SMS de revocación"
            name={['preferences', 'sendPickupReversalSms']}
            form={form}
          />
          <Toggle
            readOnly={readOnly}
            label="Envío de email de revocación"
            name={['preferences', 'sendPickupReversalEmail']}
            form={form}
          />
          <Toggle
            readOnly={readOnly}
            label="Envío de whatsApp de revocación"
            name={['preferences', 'sendPickupReversalWhatsApp']}
            form={form}
          />

          <hr />

          <h3>Notificaciones courier</h3>
          <Toggle
            readOnly={readOnly}
            label="Envío de SMS de asignación a courier"
            name={['preferences', 'sendCourierAssignedSms']}
            form={form}
          />
          <Toggle
            readOnly={readOnly}
            label="Envío de email de asignación a courier"
            name={['preferences', 'sendCourierAssignedEmail']}
            form={form}
          />

          <Toggle
            readOnly={readOnly}
            label="Envío de whatsApp de cancelación de orden"
            name={['preferences', 'sendCourierAssignedWhatsApp']}
            form={form}
          />

          <hr />
          <Toggle
            readOnly={readOnly}
            label="Envío de SMS de cancelación de orden"
            name={['preferences', 'sendCourierCancelledSms']}
            form={form}
          />
          <Toggle
            readOnly={readOnly}
            label="Envío de email de cancelación de orden"
            name={['preferences', 'sendCourierCancelledEmail']}
            form={form}
          />
          <Toggle
            readOnly={readOnly}
            label="Envío de whatsApp de cancelación de orden"
            name={['preferences', 'sendCourierCancelledWhatsApp']}
            form={form}
          />

          <hr />
          <Toggle
            readOnly={readOnly}
            label="Envío de SMS de orden entregada en locker"
            name={['preferences', 'sendCourierReadySms']}
            form={form}
          />
          <Toggle
            readOnly={readOnly}
            label="Envío de email de orden entregada en locker"
            name={['preferences', 'sendCourierReadyEmail']}
            form={form}
          />

          <Toggle
            readOnly={readOnly}
            label="Envío de whatsApp de orden entregada en locker"
            name={['preferences', 'sendCourierReadyWhatsApp']}
            form={form}
          />

          <hr />
          <Toggle
            readOnly={readOnly}
            label="Envío de SMS de orden revocada"
            name={['preferences', 'sendCourierReversalSms']}
            form={form}
          />
          <Toggle
            readOnly={readOnly}
            label="Envío de email de orden revocada"
            name={['preferences', 'sendCourierReversalEmail']}
            form={form}
          />
          <Toggle
            readOnly={readOnly}
            label="Envío de whatsApp de orden revocada"
            name={['preferences', 'sendCourierReversalWhatsApp']}
            form={form}
          />

          <hr />
          <Toggle
            readOnly={readOnly}
            label="Envío de SMS de orden completada"
            name={['preferences', 'sendCourierCompletedSms']}
            form={form}
          />
          <Toggle
            readOnly={readOnly}
            label="Envío de email de orden completada"
            name={['preferences', 'sendCourierCompletedEmail']}
            form={form}
          />
          <Toggle
            readOnly={readOnly}
            label="Envío de whatsApp de orden completada"
            name={['preferences', 'sendCourierCompletedWhatsApp']}
            form={form}
          />

          {form.getValue(['preferences', 'returnsEnabled']) && (
            <>
              <br />
              <hr />

              <h4>Notificaciones de devolución</h4>

              <Toggle
                readOnly={readOnly}
                label="Envío de SMS de devolución reservada"
                name={['preferences', 'sendReturnReservedSms']}
                form={form}
              />
              <Toggle
                readOnly={readOnly}
                label="Envío de email de devolución reservada"
                name={['preferences', 'sendReturnReservedEmail']}
                form={form}
              />
              <Toggle
                readOnly={readOnly}
                label="Envío de whatsApp de devolución reservada"
                name={['preferences', 'sendReturnReservedWhatsApp']}
                form={form}
              />

              <Toggle
                readOnly={readOnly}
                label="Envío de SMS de devolución lista"
                name={['preferences', 'sendReturnReadySms']}
                form={form}
              />
              <Toggle
                readOnly={readOnly}
                label="Envío de email de devolución lista"
                name={['preferences', 'sendReturnReadyEmail']}
                form={form}
              />
              <Toggle
                readOnly={readOnly}
                label="Envío de whatsApp de devolución lista"
                name={['preferences', 'sendReturnReadyWhatsApp']}
                form={form}
              />

              <Toggle
                readOnly={readOnly}
                label="Envío de SMS de devolución cancelada"
                name={['preferences', 'sendReturnCancelledSms']}
                form={form}
              />
              <Toggle
                readOnly={readOnly}
                label="Envío de email de devolución cancelada"
                name={['preferences', 'sendReturnCancelledEmail']}
                form={form}
              />
              <Toggle
                readOnly={readOnly}
                label="Envío de whatsApp de devolución cancelada"
                name={['preferences', 'sendReturnCancelledWhatsApp']}
                form={form}
              />

              <Toggle
                readOnly={readOnly}
                label="Envío de SMS de devolución revocada"
                name={['preferences', 'sendReturnReversalSms']}
                form={form}
              />
              <Toggle
                readOnly={readOnly}
                label="Envío de email de devolución revocada"
                name={['preferences', 'sendReturnReversalEmail']}
                form={form}
              />
              <Toggle
                readOnly={readOnly}
                label="Envío de whatsApp de devolución revocada"
                name={['preferences', 'sendReturnReversalWhatsApp']}
                form={form}
              />
            </>
          )}
          <br />
          <hr />
          <h2> Integración </h2>
          <Input
            readOnly={readOnly}
            label="Webhook URL"
            name={['preferences', 'webhookUrl']}
            placeholder="https://servicio.com"
            form={form}
          />
          <Input
            readOnly={readOnly}
            label="Webhook Secret"
            name={['preferences', 'webhookSecret']}
            placeholder="token-secreto"
            form={form}
            note={'Será enviado en la llamada y puede ser utilizado para validación.'}
          />
        </section>
      </div>
    </main>
  );
};

export default Client;
